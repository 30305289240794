<template>
  <v-dialog
    v-model="dialogProfile"
    @click:outside="$emit('update:dialogProfile', false)"
    @keydown.esc="$emit('update:dialogProfile', false)"
    width="700"
  >
    <v-card tile>
      <v-card-title class="font-weight-bold accent">
        Perfil
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          icon
          @click="$emit('update:dialogProfile', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="py-3 px-4 fill-height">
        <v-row class="fill-height">
          <v-col
            cols="12"
            md="3"
            :style="
              $vuetify.breakpoint.mdAndUp
                ? 'border-right: 1px solid rgba(0, 0, 0, 0.12) !important;'
                : ''
            "
            class="py-8"
          >
            <div
              class="d-flex justify-center align-center"
              style="position:relative"
            >
              <v-avatar size="120" style="box-shadow: 0px 0px 0px 3px #232D58;">
                <v-img
                  contain
                  :src="
                    store_avatar
                      ? store_avatar
                      : require('@/assets/avatar_placeholder.png')
                  "
                >
                </v-img>
              </v-avatar>

              <v-btn
                fab
                dark
                x-small
                absolute
                bottom
                right
                class="mb-3"
                type="file"
                @click="onButtonClick"
              >
                <input
                  accept="image/*"
                  type="file"
                  class="d-none"
                  ref="uploader"
                  @change="onFileChanged"
                />
                <v-icon>mdi-camera</v-icon>
              </v-btn>
            </div>
            <!-- <v-card flat class="mt-5">
              <v-tabs v-model="tab" vertical>
                <v-tab class="justify-start">Dados Gerais</v-tab>
              </v-tabs>
            </v-card> -->
          </v-col>
          <v-col cols="12" md="9" class="py-0 px-6">
            <!-- <v-tabs-items v-model="tab">
          
              <v-tab-item class="py-5"> -->
            <v-form ref="form">
              <v-row class="py-4">
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="usuario.name"
                    label="Nome"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.lazy="usuario.celular"
                    label="Celular"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field dense readonly value="Status:">
                    <template v-slot:append>
                      <v-switch
                        v-model="usuario.status"
                        :false-value="0"
                        :true-value="1"
                        class="mt-0 pt-0"
                        hide-details
                      >
                      </v-switch>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>

            <!-- </v-tab-item>           
            </v-tabs-items> -->

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="secondary"
                class="white--text mt-10 pb-0 "
                depressed
                @click="updateUser"
              >
                <v-icon>mdi-save</v-icon>
                salvar
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { uploadAvatar } from "@/api/geral/users.js";
import { mapState, mapActions } from "vuex";

export default {
  name: "Profile",

  props: {
    dialogProfile: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    // ThemeChanger: () => import("../../components/mudanca_tema/ThemeChanger"),
  },

  data() {
    return {
      tab: 0,

      usuario: {
        avatar: "",
      },
    };
  },

  computed: {
    ...mapState("Usuario", {
      store_usuario: (state) => state.usuario,
      store_avatar: (state) => state.avatar,
    }),
  },

  watch: {
    store_usuario: {
      handler() {
        if (this.store_usuario) {
          this.usuario = this.store_usuario;
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions("Usuario", {
      STORE_getUsuario: "getUsuario",
      STORE_updateUsuario: "updateUsuario",
    }),

    onButtonClick() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      const file = e.target.files[0];

      const fd = new FormData();
      fd.append("avatar", file, file.name);

      uploadAvatar(this.usuario.id, fd)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Avatar atualizado com sucesso!");
            this.STORE_getUsuario();
          }
        })
        .catch(() => {});
    },

    updateUser() {
      this.$Progress.start();

      let usuario = {};
      usuario.id = this.usuario.id;
      usuario.name = this.usuario.name;
      usuario.status = this.usuario.status;

      this.STORE_updateUsuario({
        id: usuario.id,
        usuario: usuario,
      })
        .then(() => {
          this.$toast.success("Usuario atualizado com sucesso!");
          this.$emit("update:dialogProfile", false);
          this.$Progress.finish();
        })
        .catch(() => {
          this.$Progress.fail();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card--reveal {
  align-items: flex-start;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.btn-toggle-mobile {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  & > .v-btn.v-btn:not(:first-child) {
    border-left-width: thin;
  }
  & > .v-btn.v-btn:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  & > .v-btn.v-btn:last-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
